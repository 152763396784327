import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { CompanyTag } from '../company-tag/CompanyTag';
import { Icons, SvgIcon } from '../svg-icon';
import { ProfilePicture } from '../profile-picture/ProfilePicture';
import { MediaResponse } from '@homeproved/shared/data-access';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import {
  ReviewCardAuthor,
  AutoTranslatedIcon,
  ReviewCardBody,
  ReviewCardDate,
  ReviewCardHeader,
  ReviewCardName,
  NewLabel,
  ProConChipWrapper,
  ReadMore,
  ReviewButtonContainer,
  ReviewCardImage,
  StyledReviewCard,
  ReviewCardSvgIcon,
  ReviewCardText,
  ReviewCardTitle,
  ReviewButton,
  ReviewCardSubHeader,
} from './Atoms';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Stars } from '@homeproved/shared/ui';
import { ProConPoint } from '@homeproved/shared/data-access';
import { ProConChip } from '../pro-con-chip/ProConChip';
import moment from 'moment';
import { useWindowSize } from './hooks/useWindowSize';
import { Tooltip } from '../tooltip/Tooltip';
import { decode } from 'html-entities';
import { useRouter } from 'next/router';
import styled from 'styled-components';

export type Review = {
  id: number;
  picture?: MediaResponse;
  isTranslation: boolean;
  files?: MediaResponse[];
  audio?: MediaResponse[];
  video?: MediaResponse[];
  videoThumbnail?: MediaResponse[];
  initials: string;
  business?: string;
  rating: number;
  date: string;
  title: string;
  text?: string;
  description?: string;
  typeOfWork?: string;
  city?: string;
  companyId: number;
  companySlug: string;
  screenName: string;
};

export type ReviewCardProps = {
  review: Review;
  isMobile: boolean;
  isTablet?: boolean;
  readmoreButton?: boolean;
  equalHeight?: boolean;
  bordered?: boolean;
  showButtons?: boolean;
  checked?: boolean;
  shared?: boolean;
  favorite?: boolean;
  answered?: boolean;
  isNew?: boolean;
  teaser?: boolean;
  showQuoteSign?: boolean;
  navigateToReview?: (slug: string, rid: number) => void;
  dragging?: boolean;
  proCons?: ProConPoint[];
  bigBalloon?: boolean;
  selectable?: boolean;
  onClick?: () => void;
  getComPath?: any;
  href: string;
};

const StyledVideoIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ReviewCard: FC<ReviewCardProps> = ({
  review,
  isMobile,
  isTablet,
  bordered,
  showButtons,
  checked,
  shared,
  favorite,
  answered,
  readmoreButton,
  isNew,
  navigateToReview,
  teaser,
  showQuoteSign,
  proCons,
  bigBalloon,
  dragging,
  selectable,
  equalHeight,
  onClick,
  getComPath,
  href,
}) => {
  const router = useRouter();
  const theme = useTheme();
  const { t } = useTranslation();

  const [titleHeight, setTitleHeight] = useState(26);

  const ref = useRef(null);
  const windowSize = useWindowSize(equalHeight);

  useEffect(() => {
    setTitleHeight(ref.current.clientHeight);
  }, [ref, windowSize, equalHeight, review.title, review.text]);

  const handleOnItemClick = useCallback(
    (e) => {
      if (dragging) e.stopPropagation();
      else {
        navigateToReview?.(review.companySlug, review.id);
        onClick && onClick();
      }
    },
    [dragging]
  );
  const hasContent = () => {
    return review?.files && review?.files.length;
  };

  const isImage = (file) => {
    return file?.data.mime_type?.includes('image');
  };
  return (
    <StyledReviewCard
      bordered={bordered}
      mobile={isMobile}
      onClick={handleOnItemClick}
      selectable={selectable}
      equalHeight={!isTablet && equalHeight}
    >
      {isNew && (
        <NewLabel mobile={isMobile} variant="body1">
          {t('app.pro.pages.reviews.new')}
        </NewLabel>
      )}
      <ReviewCardHeader>
        <ReviewCardAuthor>
          <ProfilePicture initials={review.initials} picture={review.picture} />
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            ml={1}
          >
            <Box display="flex" flexDirection="column">
              <ReviewCardName variant="body1">{review.screenName}</ReviewCardName>
              <Stars count={review.rating} size={1.8} />
            </Box>
            <ReviewCardDate variant="body1">
              {moment(review.date).format('DD/MM/YY')}
            </ReviewCardDate>
          </Box>
        </ReviewCardAuthor>
      </ReviewCardHeader>
      {review.city && review.typeOfWork && (
        <ReviewCardSubHeader>
          <img src="/location.svg" />
          {review.city}
          <img src="/work.svg" />
          {review.typeOfWork}
        </ReviewCardSubHeader>
      )}
      <ReviewCardBody mobile={isMobile} teaser={teaser}>
        {showQuoteSign && (
          <ReviewCardSvgIcon
            icon={Icons.QUOTE}
            color={theme.palette.grey['A100']}
            mobile={isMobile}
          />
        )}
        <div ref={ref} style={{ marginBottom: '0.5rem' }}>
          <ReviewCardTitle variant="body1" teaser={teaser}>
            {decode(review.title)}
          </ReviewCardTitle>
        </div>
        {review.description !== '' ? (
          <Box>
            <ReviewCardText
              variant="body1"
              teaser={teaser}
              lines={hasContent() ? 2 : !isMobile && titleHeight <= 26 ? 6 : 5}
            >
              {decode(review.description)}
            </ReviewCardText>
          </Box>
        ) : (
          <Box />
        )}
        {review.files?.length > 0 && (
          <ReviewCardImage>
            {review.files?.slice(0, 3).map((file) => {
              if (isImage(file)) {
                return (
                  <Box position="relative" width="33%" display="flex">
                    <img
                      src={file.data.conversions?.['small'] || file.data.original}
                      alt={file.data.fileName}
                    />
                  </Box>
                );
              } else {
                return (
                  <Box position="relative" width="33%" display="flex">
                    <img
                      src={file.data.customProperties?.thumbnail?.data?.original}
                      alt={file.data.customProperties?.thumbnail?.data?.fileName}
                    />
                    <StyledVideoIcon>
                      <img className="icon" src="/Video_play.svg" />
                    </StyledVideoIcon>
                  </Box>
                );
              }
            })}
          </ReviewCardImage>
        )}
        {review.business && <CompanyTag text={review.business} icon={Icons.HELMET_SOLID} />}
        {proCons?.length > 0 && (
          <ProConChipWrapper
            mobile={isMobile}
            tablet={isTablet}
            equalHeight={equalHeight}
            extraPaddingRight={review.isTranslation}
          >
            {proCons.map(({ data }) => (
              <ProConChip
                key={data.id}
                pro={data.type === 'pro'}
                text={data.label}
                mobile={isMobile}
                size="small"
              />
            ))}
          </ProConChipWrapper>
        )}
      </ReviewCardBody>
      {showButtons && (
        <ReviewButtonContainer>
          <ReviewButton onClick={navigateToReview} active={answered}>
            <img src={answered ? 'Beantwoord.svg' : '/Beantwoorden.svg'} />
            {answered
              ? t('app.pro.reviews.answered', 'Beantwoord')
              : t('app.pro.reviews.answer', 'Beantwoorden')}
          </ReviewButton>
          <ReviewButton
            onClick={(e) => {
              e.stopPropagation();
              router.push(getComPath('/social-share') + `?review=${review.id}`);
            }}
          >
            <img src="/Delen 2.svg" />
            {t('app.pro.reviews.share', 'Delen')}
          </ReviewButton>
        </ReviewButtonContainer>
      )}
      {/*<ReviewCardRatingBalloon color={color} size={bigBalloon ? 'big' : 'medium'}>*/}
      {/*  {review.rating}*/}
      {/*</ReviewCardRatingBalloon>*/}
      {review.isTranslation && (
        <AutoTranslatedIcon
          position={
            showQuoteSign ? 'topRight' : readmoreButton ? 'bottomRightWithPadding' : 'bottomRight'
          }
        >
          <Tooltip title={ReactHtmlParser(t('reviews.autoTranslation'))}>
            <span>
              <SvgIcon icon={Icons.AUTO_TRANSLATED} size={2} />
            </span>
          </Tooltip>
        </AutoTranslatedIcon>
      )}
      {readmoreButton && (
        <ReadMore>
          {t('app.com.pages.housingAdvice.legalAndFinancial.cta')}
          <SvgIcon viewBox="0 0 24 24" icon={Icons.ARROW_RIGHT} color="#AED6D4" size={1.8} />
        </ReadMore>
      )}
    </StyledReviewCard>
  );
};
