import {Typography} from '@material-ui/core';
import React, {FC} from 'react';
import styled from 'styled-components';
import {MediaResponse} from '@homeproved/shared/data-access';

export type ProfilePictureProps = {
  picture?: MediaResponse;
  pictureUrl?: string;
  initials: string;
  size?: number;
  fontSize?: number;
};

const StyledProfilePicture = styled(({size, ...restProps}) => <div {...restProps} />)`
  width: ${({size}) => `${size}rem`};
  height: ${({size}) => `${size}rem`};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.palette.grey['A200']};
  flex-shrink: 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
`;

export const Initials = styled(({fontSize, color, children, ...restProps}) => (
  <Typography {...restProps} >
    <span>{children}</span>
  </Typography>
))`
  background: url('/Ellipse.png') center center no-repeat;
  background-size: contain;
  font-size: ${({fontSize}) => `${fontSize}rem`};
  color: ${({color}) => (color ? color : '#fff')};
  font-family: ${({theme}) => theme.config.fonts.PTSans};
  font-weight: 800;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    position : relative;
    font-size: ${({fontSize}) => `${fontSize - 2}rem`};
  }
`;

const Img = styled.img`
  color: #fff;
  font-family: ${({theme}) => theme.config.fonts.PTSans};
  font-weight: 800;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

export const ProfilePicture: FC<ProfilePictureProps> = ({
                                                          picture,
                                                          pictureUrl,
                                                          initials,
                                                          size = 4.5,
                                                          fontSize = 1.8,
                                                        }) => {
  return (
    <StyledProfilePicture size={size}>
      {!picture && !pictureUrl ? (
        <Initials fontSize={fontSize}>{initials}</Initials>
      ) : (
        <Img src={picture ? picture.data.original : pictureUrl} alt={initials} loading="lazy"/>
      )}
    </StyledProfilePicture>
  );
};
