import styled from 'styled-components';
import React from 'react';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  margin: 3rem 0;
  padding: 3rem 0;
  background: #fff;
  border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
  box-shadow: ${({ theme }) => theme.config.defaultBoxShadow};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Company = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  display: flex;
  flex-direction: ${({ mobile }) => mobile && 'column'};
  align-items: center;
  padding: 0 3rem;
  order: 1;
  margin-bottom: 2rem;
`;

export const Rating = styled(({ isMobile, ...restProps }) => <div {...restProps} />)`
  margin: ${({ isMobile }) => isMobile && '2rem 0 0'};
  padding: 0 1rem;
  order: 3;
  display: flex;
  justify-content: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.xs + 'px'}) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.sm + 'px'}) {
    order: 2;
    margin: 0 2rem 0 auto;
    padding: 0;
  }
`;

export const CompanyLogo = styled(({ mobile, size, ...restProps }) => <div {...restProps} />)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  height: 20rem;
  img {
    display: block;
    padding: 1rem;
    width: ${({ mobile }) => (mobile ? '15rem' : '20rem')};
    flex-shrink: 0;
  }
`;

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  text-decoration: none;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
`;

export const StyledLine = styled.div`
  width: 6.5rem;
  height: 0.3rem;
  margin: 0 auto;
  margin-top: 1rem;
  background: linear-gradient(to right, #d9023a 0%, #ef8c3f 100%);
`;

export const StyledA = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

export const ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 1rem;
`;

export const ScoreNumber = styled(({ size, ...restProps }) => <span {...restProps} />)`
  font-size: ${({ size }) => (size === 'large' ? '4rem' : '2rem')};
  font-family: ${({ theme }) => theme.config.fonts.CabritoExtended};
  font-weight: 700;
`;

export const Max = styled(({ size, ...restProps }) => <span {...restProps} />)`
  font-size: ${({ size }) => (size === 'large' ? '2rem' : '1rem')};
  align-self: flex-end;
  padding: ${({ size }) => (size === 'large' ? '0 0 0 0.5rem' : '0 0 0.2rem')};
  font-family: ${({ theme }) => theme.config.fonts.CabritoExtended};
`;
