import { FC } from 'react';

type Props = {
  schema: {
    '@context': 'http://schema.org';
    '@type': 'Review';
    itemReviewed: {
      '@type': 'LocalBusiness';
      name: string;
      image: string;
      address: {
        '@type': 'PostalAddress';
        streetAddress: string;
        addressLocality: string;
        postalCode: string;
      };
      aggregateRating?: {
        '@type': 'AggregateRating';
        ratingValue: string;
        ratingCount: string;
        bestRating: string;
        worstRating: string;
      };
    };
    reviewRating?: {
      '@type': 'Rating';
      ratingValue: string;
    };
    reviewBody?: string;
    author: {
      '@type': string;
      name: string;
    };
    publisher: {
      '@type': 'Organization';
      name: 'Homeproved.com';
    };
  }[];
};

export const JSONLD: FC<Props> = ({ schema }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};
