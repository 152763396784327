import React from 'react';
import { CustomGrid, CustomGridItem } from '../Atoms';
import ReactHtmlParser from 'react-html-parser';

export const Html = ({ fields, isMobile, isTablet }) => {
  return (
    <CustomGrid mobile={isMobile} noMarginBottom={false}>
      <CustomGridItem variant="center" mobile={isMobile} tablet={isTablet}>
        {ReactHtmlParser(fields.html)}
      </CustomGridItem>
    </CustomGrid>
  );
};
