import React, {FC} from 'react';
import {
  CompaniesApiFactory,
  CompanyData,
  useApiFactory,
  useQueryFetch,
} from '@homeproved/shared/data-access';
import {
  Wrapper,
  Company,
  Rating,
  Title,
  StyledA,
  Container,
  CompanyInfo,
  StyledLine, ScoreWrapper, ScoreNumber, Max,
  CompanyLogo
} from './Atoms';
import {useMediaQuery, useTheme} from '@material-ui/core';
import Link from 'next/link';
import {getInitials} from "@homeproved/shared/util";
import {Initials} from "../profile-picture/ProfilePicture";
import Stars from "../stars/Stars";
import LocationTag from "../location-tag/LocationTag";

export type CompanyCarouselCardProps = {
  company: CompanyData;
  companyPath: string;
  dragging?: boolean;
};

export const CompanyCarouselCard: FC<CompanyCarouselCardProps> = ({company, companyPath, dragging}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const companiesApi = useApiFactory(CompaniesApiFactory);
  const {query: companyScore} = useQueryFetch(['companyScore', company?.slug], () =>
    companiesApi.apiCompaniesCompanyScoreGet(company?.id?.toString())
  );
  const scoreCount = companyScore?.data?.data?.score ? companyScore.data.data.score : 0

  return company !== null ? (<Container>
      <Wrapper>
        <Company mobile={isMobile}>
          <Link href={companyPath} passHref>
            <StyledA href={companyPath}>
                <CompanyLogo>
                  {!company?.logo ? <Initials fontSize={6} background>{getInitials(company?.name)}</Initials>:
                    <img
                      src={company?.logo?.data?.conversions?.['small'] || company?.logo?.data?.original}
                      alt={company?.name}
                    />}
                </CompanyLogo>
            </StyledA>
          </Link>
        </Company>
        <Rating isMobile={isMobile}>
          <Stars
            count={scoreCount}
            size={2.4}
            showRating
          />
        </Rating>
      </Wrapper>
      <CompanyInfo>
        <Title>
          <Link href={companyPath} passHref>
            <StyledA href={companyPath}>{company.name}</StyledA>
          </Link>
          <StyledLine/>
        </Title>
        <LocationTag location={company.city} align={isMobile ? 'center' : 'left'}/>
      </CompanyInfo>
    </Container>
  ) : null;
};
