import React from 'react';
import { Caption, CoveredImage, CustomGrid, CustomGridItem } from '../Atoms';

export const DoublePhoto = ({ fields, isMobile, isTablet }) => {
  return (
    <CustomGrid mobile={isMobile} noMarginBottom={false}>
      <CustomGridItem
        variant="right"
        mobile={isMobile}
        tablet={isTablet}
        order={1}
        marginBottom={isTablet && '2rem'}
      >
        <CoveredImage
          src={fields.left_image}
          alt={fields.alt_left}
          title={fields.alt_left}
          loading="lazy"
          maxWidth={'100%'}
          dontCover={isMobile}
        />
        <Caption variant="caption" maxWidth={'100%'}>
          {fields.caption_left}
        </Caption>
      </CustomGridItem>
      <CustomGridItem variant="left" mobile={isMobile} tablet={isTablet} order={2}>
        <CoveredImage
          src={fields.right_image}
          alt={fields.alt_right}
          title={fields.alt_right}
          loading="lazy"
          maxWidth={'100%'}
          dontCover={isMobile}
        />
        <Caption variant="caption" maxWidth={'100%'}>
          {fields.caption_right}
        </Caption>
      </CustomGridItem>
    </CustomGrid>
  );
};
