import { useMediaQuery, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';

export const useNavVisible = () => {
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.sm));
  const [navVisible, setNavVisible] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setNavVisible(isSmallDesktop ? position < 100 : position < 80);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return navVisible;
};
