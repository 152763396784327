import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { Button } from '../buttons/Button';

type InviteCardProps = {
  isMobile: boolean;
  inviteLink: string;
};

const Wrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  position: relative;
  padding: 8rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.config.gradients.rotated};
  border-radius: ${({ theme, mobile }) => (mobile ? 0 : theme.config.defaultBorderRadius)};
  margin-bottom: ${({ mobile }) => (mobile ? '0' : '3rem')};
`;

const PaperPlane = styled(({ mobile, alt, ...restProps }) => <img alt={alt} {...restProps} />)`
  position: absolute;
  top: 1rem;
  left: 2rem;
  width: ${({ mobile }) => (mobile ? '56%' : '69%')};
`;

const Title = styled.div`
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.8rem;
  &:after {
    content: '';
    display: block;
    width: 8rem;
    height: 0.2rem;
    background-color: #fff;
    margin-top: 0.8rem;
  }
`;

const Text = styled(Typography)`
  text-align: center;
  color: #fff;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-size: 1.4rem;
  margin-bottom: 3rem;
`;

export const InviteCard: FC<InviteCardProps> = ({ isMobile, inviteLink }) => {
  const { t } = useTranslation();
  return (
    <Wrapper mobile={isMobile}>
      <PaperPlane src="/vlieger.svg" alt="" mobile={isMobile} />
      <Title>{t('app.pro.pages.reviews.invite.title')}</Title>
      <Text variant="body1">{t('app.pro.pages.reviews.invite.text')}</Text>
      <Button variant="white" href={inviteLink}>
        {t('app.pro.pages.reviews.invite.cta')}
      </Button>
    </Wrapper>
  );
};
