import React, { FC, useState } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { MediaResponse } from '@homeproved/shared/data-access';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ImageGalleryModal } from '@homeproved/shared/ui';

export type ImageGalleryProps = {
  images: MediaResponse[];
  copyright?: string;
  bigger?: boolean;
  isMobile?: boolean;
  company?: { name: string; url: string };
};

const Images = styled(({ bigger, mobile, ...restProps }) => <div {...restProps} />)`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: ${({ bigger, mobile }) =>
      bigger
        ? mobile
          ? '0 0 calc(50% - 1rem)'
          : '0 0 calc(25% - 1rem)'
        : '0 0 calc(33.33% - 1rem)'};
    position: relative;
    margin: 0.5rem;

    &:first-child {
      flex: 0 0 calc(100% - 1rem);
    }
  }
  img {
    width: 100%;
    vertical-align: bottom;
    cursor: pointer;
  }
`;

const MoreCount = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  font-size: 5rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
`;

const Copyright = styled.div`
  margin-top: -2rem;
  padding: 2rem 0.5rem 0.5rem;
  font-size: 1.2rem;
  font-style: italic;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  background: linear-gradient(0deg, rgba(247, 247, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
`;

export const ImageGallery: FC<ImageGalleryProps> = ({
  images,
  copyright,
  bigger,
  isMobile,
  company,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [initialSlide, setInitialSlide] = useState<number>(2);

  const handleClick = (index = initialSlide) => {
    setInitialSlide(index);
    setOpen((open) => !open);
  };
  return (
    <>
      <Images bigger={bigger} mobile={isMobile}>
        {images.slice(0, bigger ? (isMobile ? 3 : 5) : 4).map((image, index) => {
          return (
            <div
              key={index}
              onClick={() =>
                handleClick(
                  index !== (bigger ? (isMobile ? 2 : 4) : 3)
                    ? index
                    : images.length <= (bigger ? (isMobile ? 2 : 5) : 4)
                    ? index
                    : 0
                )
              }
            >
              <img
                src={
                  index === 0
                    ? image?.data?.conversions?.['small'] ?? image?.data?.original
                    : image?.data?.conversions?.['small'] ?? image?.data?.original
                }
                alt=""
                loading="lazy"
              />
              {index === (bigger ? (isMobile ? 2 : 4) : 3) &&
                images.length > (bigger ? (isMobile ? 3 : 5) : 4) && (
                  <MoreCount>{`+${images.length - (bigger ? (isMobile ? 3 : 5) : 4)}`}</MoreCount>
                )}
            </div>
          );
        })}
      </Images>
      {!!copyright && <Copyright>{ReactHtmlParser(copyright)}</Copyright>}
      <ImageGalleryModal
        open={open}
        setOpen={setOpen}
        images={images}
        company={company}
        initialSlide={initialSlide}
      />
    </>
  );
};
