import React from 'react';
import { Caption, CustomGrid, CustomGridItem, FlexibleContentText, StyledImage } from '../Atoms';
import ReactHtmlParser from 'react-html-parser';

export const PhotoText = ({ fields, isMobile, isTablet }) => {
  return (
    <CustomGrid mobile={isMobile} noMarginBottom={false}>
      <CustomGridItem variant="left" mobile={isMobile} tablet={isTablet} order={1}>
        <StyledImage
          src={fields.image}
          alt={fields['caption']}
          title={fields['caption']}
          loading="lazy"
          maxWidth={'100%'}
        />
        <Caption variant="caption" maxWidth={'100%'}>
          {fields['caption']}
        </Caption>
      </CustomGridItem>
      <CustomGridItem variant="right" mobile={isMobile} tablet={isTablet} order={2}>
        <FlexibleContentText tablet={isTablet} mobile={isMobile} maxWidth={'100%'}>
          {ReactHtmlParser(fields['text'])}
        </FlexibleContentText>
      </CustomGridItem>
    </CustomGrid>
  );
};
