import React, { FC, useMemo, useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Icons, SvgIcon } from '../svg-icon';
import { MediaResponse } from '@homeproved/shared/data-access';
import { Box, Fade, useMediaQuery, useTheme } from '@material-ui/core';
import { Button } from '../buttons';
import { VideoPlayer } from '../video-player/VideoPlayer';

export type ImageGalleryModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  images: MediaResponse[];
  company?: { name: string; url: string };
  initialSlide?: number;
};

const SliderWrapper = styled.div`
  padding: 3rem;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SliderContent = styled.div`
  padding: 2rem;
  width: 115.5rem;
  max-width: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.config.defaultBorderRadius};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm + 'px'}) {
    padding: 6rem;
  }
`;

const SliderTop = styled(Slider)`
  .slick-list {
    transition: height 250ms linear;
  }

  .slick-slider {
    position: static;
  }
  .slick-slide {
    padding: 4rem;
    div {
      outline: none !important;
    }
    img {
      margin: auto;
      box-shadow: 0 0 2rem 0 rgb(0 0 0 / 25%);
      max-width: 100%;
      border: 1rem solid rgba(255, 255, 255, 0.8);
      border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
      max-height: 70vh;
    }
  }
  .slick-arrow {
    width: auto;
    height: auto;
    &:before {
      content: none;
    }
    &.slick-disabled {
      svg,
      path {
        fill: ${({ theme }) => theme.palette.grey[500]};
      }
      cursor: default;
    }
    &.slick-prev {
      left: 0;
      transform: translate(0, -50%);
    }
    &.slick-next {
      right: 0;
      transform: translate(3.9rem, -50%);
    }
  }
`;

const SelectableImage = styled.div``;

const SliderBottom = styled(({ imageCount, isMobile, innerRef, ...restProps }) => (
  <Slider ref={innerRef} {...restProps} />
))`
  .slick-slide {
    padding: 1rem;
    position: relative;
    cursor: pointer;

    > div {
      position: relative;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
      > div {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    img {
      object-fit: cover;
      width: 100%;
      height: ${({ isMobile }) => (isMobile ? '60px' : '100px')};
      transition: transform 0.3s ease;
      padding: 1rem;
      background: rgba(255, 255, 255, 0.8);
      border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
    }
    &:hover {
      img {
        transform: scale(1.02);
      }
    }
    &.slick-center {
      ${SelectableImage} {
        img {
          background: ${({ theme }) => theme.config.gradients.rotated};
        }
      }
    }
    &.slick-cloned {
      display: ${({ imageCount }) => imageCount < 6 && 'none'};
      @media screen and (max-width: 900px) {
        display: ${({ imageCount }) => (imageCount < 4 ? 'none' : 'block')};
      }
      @media screen and (max-width: 600px) {
        display: ${({ imageCount }) => (imageCount < 3 ? 'none' : 'block')};
      }
    }
  }
`;

const SliderClose = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm + 'px'}) {
    right: 12rem;
    top: 12rem;
    z-index: 1;
  }
`;

const LeftArrow = styled(({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <SvgIcon size={6} color="rgba(255, 255, 255, 0.8)" icon={Icons.ANGLE_LEFT} />
  </div>
))`
  z-index: 9;
`;

const RightArrow = styled(({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <SvgIcon size={6} color="rgba(255, 255, 255, 0.8)" icon={Icons.ANGLE_RIGHT} />
  </div>
))`
  z-index: 9;
`;

export const ImageGalleryModal: FC<ImageGalleryModalProps> = ({
  images,
  company,
  initialSlide,
  open,
  setOpen,
}) => {
  const [sliderOne, setSliderOne] = useState(null);
  const [sliderTwo, setSliderTwo] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));

  const sliderTopSettings = useMemo(
    () => ({
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      adaptiveHeight: true,
      initialSlide: initialSlide,
      nextArrow: <RightArrow />,
      prevArrow: <LeftArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    }),
    [initialSlide]
  );

  const sliderBottomSettings = useMemo(
    () => ({
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      centerMode: true,
      initialSlide: initialSlide,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [initialSlide]
  );

  const isImage = (file) => {
    return file?.data.mime_type?.includes('image');
  };

  return (
    <Fade in={open} unmountOnExit>
      <SliderWrapper onClick={() => setOpen(false)}>
        <SliderContent onClick={(e) => e.stopPropagation()}>
          {company && company?.name && company?.url && (
            <Box>
              <Button
                variant="gradient"
                icon={Icons.HELMET}
                href={company.url}
                pill={false}
                arrow="none"
              >
                {company.name}
              </Button>
            </Box>
          )}
          <SliderClose onClick={() => setOpen(false)}>
            <SvgIcon size={2} icon={Icons.CROSS} color="gradient" />
          </SliderClose>
          <SliderTop
            asNavFor={sliderOne}
            ref={(slider) => setSliderTwo(slider)}
            {...sliderTopSettings}
          >
            {images.map((image, index) => {
              return isImage(image) ? (
                <div key={index}>
                  <img src={image?.data?.original} alt="" />
                </div>
              ) : (
                <div key={index}>
                  <VideoPlayer
                    stopPropagation={(e) => e.stopPropagation()}
                    card={false}
                    video={image as MediaResponse}
                    thumbnail={image.data.customProperties?.thumbnail}
                  />
                </div>
              );
            })}
          </SliderTop>
          <SliderBottom
            asNavFor={sliderTwo}
            isMobile={isMobile}
            innerRef={(slider) => setSliderOne(slider)}
            {...sliderBottomSettings}
            imageCount={images.length}
          >
            {images.map((image, index) => {
              return (
                <SelectableImage key={index}>
                  {isImage(image) ? (
                    <Box position="relative" display="flex">
                      <img
                        src={image.data.conversions?.['small'] || image.data.original}
                        alt={image.data.fileName}
                      />
                    </Box>
                  ) : (
                    <Box position="relative" display="flex">
                      <img
                        src={image.data.customProperties?.thumbnail?.data?.original}
                        alt={image.data.customProperties?.thumbnail?.data?.fileName}
                      />
                      <Box position="absolute" top="5" left="5">
                        <img className="icon" src="/Video_play.svg" />
                      </Box>
                    </Box>
                  )}
                </SelectableImage>
              );
            })}
          </SliderBottom>
        </SliderContent>
      </SliderWrapper>
    </Fade>
  );
};
