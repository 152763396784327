import React, { FC } from 'react';
import styled from 'styled-components';

export type RatingProps = {
  value: number;
  max: number;
  color?: 'white' | 'dark';
  className?: string;
  size?: number;
};

const Wrapper = styled(({ color, ...restProps }) => <div {...restProps} />)`
  font-weight: 900;
  display: flex;
  align-items: baseline;
  color: ${({ color, theme }) => (color === 'white' ? '#FFF' : theme.palette.grey['800'])};
`;

const StyledRatingValue = styled(({ size, ...restProps }) => <div {...restProps} />)`
  font-size: ${({ size }) => (size ? size : 3)}rem;
`;

const StyledRatingMax = styled(({ size, ...restProps }) => <div {...restProps} />)`
  font-size: ${({ size }) => (size ? size / 3 : 1)}rem;
`;

export const Rating: FC<RatingProps> = ({ value, max, color = 'dark', className, size }) => {
  return (
    <Wrapper color={color} className={className}>
      <StyledRatingValue size={size}>{value.toPrecision(2).replace('.', ',')}</StyledRatingValue>
      <StyledRatingMax size={size}>/ {max}</StyledRatingMax>
    </Wrapper>
  );
};
