import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../theme';
import { Icons } from '../svg-icon';
import { IconButton } from '../buttons';
import { Accordion } from '../accordion/Accordion';
import { SectorSidebarItem } from '../types';

type SectorSidebarProps = {
  offCanvas: boolean;
  onToggleOffCanvas: () => void;
  items: SectorSidebarItem[];
};

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
`;

const SidebarMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.grey[200]}`};
  position: relative;
  padding: 1rem 0 1.5rem;
`;

const Body = styled.div`
  flex: 1;
  overflow: auto;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: -0.5rem;
  top: 0.8rem;
`;

export const SectorSidebar: FC<SectorSidebarProps> = ({ items, onToggleOffCanvas }) => {
  const { t } = useTranslation();
  return (
    <SidebarMenu>
      <Header>
        <Title>{t('app.com.pages.housingAdvice.menu.title')}</Title>
        <StyledIconButton
          icon={Icons.CROSS}
          variant="white"
          iconColor={theme.palette.grey['700']}
          onClick={onToggleOffCanvas}
          size={1.2}
        />
      </Header>
      <Body>
        <Accordion items={items} onToggleOffCanvas={onToggleOffCanvas} />
      </Body>
    </SidebarMenu>
  );
};
