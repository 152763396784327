import React, { FC } from 'react';
import styled from 'styled-components';
import { Chip as MuiChip } from '@material-ui/core';

type ChipSize = 'normal' | 'large';

export type ChipProps = {
  color?: string;
  textColor?: string;
  label?: string;
  size?: ChipSize;
  className?: string;
};

const StyledChip = styled(({ size, color, textColor, ...restProps }) => <MuiChip {...restProps} />)`
  display: inline-flex;
  height: auto;
  font-weight: 600;
  font-size: ${({ size }) => (size === 'large' ? '1.5rem' : '1.2rem')};
  ${({ theme, color, textColor }) => `
    font-family: ${theme.config.fonts.PTSans};
    background: ${color ? color : theme.palette.grey['A200']};
    color: ${textColor ? textColor : theme.palette.text.primary};
    border-radius: ${theme.config.defaultBorderRadius};
  `}
  .MuiChip-label {
    display: flex;
    align-items: center;
    padding: ${({ size }) => (size === 'large' ? '0.6rem' : '0.3rem 0.6rem')};
  }
`;

export const Chip: FC<ChipProps> = ({
  label,
  color,
  textColor,
  size = 'normal',
  className,
  children,
}) => (
  <StyledChip className={className} textColor={textColor} label={label} color={color} size={size}>
    {children}
  </StyledChip>
);
