import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icons, SvgIcon } from '../svg-icon';
import ReactHtmlParser from 'react-html-parser';
import { Socials } from '../socials/Socials';

export type ContactBubbleProps = {
  showSocial?: boolean;
  backgroundColor: string;
  cornerPosition: 'right' | 'left';
  isMobile: boolean;
  isTablet: boolean;
  isSmallDesktop: boolean;
  width: string;
  stayBubbleOnMobile?: boolean;
};

const BubbleWrapper = styled(
  ({ backgroundColor, tablet, mobile, stayBubbleOnMobile, width, ...restProps }) => (
    <div {...restProps} />
  )
)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${({ tablet, backgroundColor, stayBubbleOnMobile, width }) =>
    tablet &&
    (stayBubbleOnMobile
      ? `
    width: ${width}rem;
    `
      : `
    width: calc(100% + 8rem);
    background: ${backgroundColor};
    position: relative;
    margin-top: 4rem;
    margin-left: -4rem;
    margin-right: -4rem;
  `)}
  ${({ mobile, stayBubbleOnMobile }) =>
    mobile &&
    !stayBubbleOnMobile &&
    `
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `}
`;

const BubbleWrapperInside = styled(({ tablet, stayBubbleOnMobile, ...restProps }) => (
  <div {...restProps} />
))`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${({ tablet, stayBubbleOnMobile }) =>
    tablet &&
    !stayBubbleOnMobile &&
    `
    width: 100%;
    align-items: center;
  `}
`;

const Bubble = styled(
  ({
    tablet,
    smallDesktop,
    cornerPosition,
    backgroundColor,
    width,
    stayBubbleOnMobile,
    ...restProps
  }) => <div {...restProps} />
)`
  background: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => `${width}rem`};
  height: ${({ width }) => `${width}rem`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-end-end-radius: ${({ cornerPosition }) => (cornerPosition === 'left' ? '50%' : 0)};
  border-bottom-left-radius: ${({ cornerPosition }) => (cornerPosition === 'right' ? '50%' : 0)};
  ${({ tablet, stayBubbleOnMobile }) =>
    tablet &&
    !stayBubbleOnMobile &&
    `
    width: 100%;
    height: auto;
    padding: 2rem 0;
  `}
  ${({ smallDesktop }) =>
    smallDesktop &&
    `
    width: 30rem;
    height: 30rem;
  `}
`;

const BubbleInner = styled.div`
  max-width: 60%;
`;

const BubbleHeader = styled(({ tablet, stayBubbleOnMobile, ...restProps }) => (
  <div {...restProps} />
))`
  font-weight: bolder;
  font-size: 1.8rem;
  padding-bottom: 0.4rem;
  ${({ tablet, stayBubbleOnMobile }) =>
    tablet &&
    !stayBubbleOnMobile &&
    `
    padding-bottom: 2rem;
    text-align: center;
  `}
`;

const BubbleLine = styled.div`
  display: flex;
  margin-top: 1rem;
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
`;
const BubbleLineA = styled.a`
  display: flex;
  margin-top: 1rem;
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BTW = styled.div`
  font-weight: 700;
  letter-spacing: 0;
`;

const ContactIcon = styled.div`
  width: 3.2rem;
`;

const Span = styled(Typography)`
  padding-left: 1rem;
  width: calc(100% - 3.2rem);
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
`;

export const ContactBubble: FC<ContactBubbleProps> = ({
  showSocial,
  backgroundColor,
  cornerPosition,
  isMobile,
  isTablet,
  isSmallDesktop,
  width,
  stayBubbleOnMobile,
}) => {
  const { t } = useTranslation();

  return (
    <BubbleWrapper
      backgroundColor={backgroundColor}
      tablet={isTablet}
      mobile={isMobile}
      stayBubbleOnMobile={stayBubbleOnMobile}
      width={width}
    >
      <BubbleWrapperInside tablet={isTablet} stayBubbleOnMobile={stayBubbleOnMobile}>
        <Bubble
          tablet={isTablet}
          smallDesktop={isSmallDesktop}
          cornerPosition={cornerPosition}
          backgroundColor={backgroundColor}
          width={width}
          stayBubbleOnMobile={stayBubbleOnMobile}
        >
          <BubbleInner>
            <BubbleHeader tablet={isTablet} stayBubbleOnMobile={stayBubbleOnMobile}>
              {t('app.com.pages.contact.details')}
            </BubbleHeader>
            {/*<BubbleLineA href={`tel:${t('app.com.pages.contact.phone')}`}>*/}
            {/*  <ContactIcon>*/}
            {/*    <SvgIcon icon={Icons.PHONE} size={2} />*/}
            {/*  </ContactIcon>*/}
            {/*  <Span variant="body1">{t('app.com.pages.contact.phone')}</Span>*/}
            {/*</BubbleLineA>*/}
            <BubbleLineA href={`mailto:${t('app.com.pages.contact.email')}`}>
              <ContactIcon>
                <SvgIcon icon={Icons.EMAIL} size={2} />
              </ContactIcon>
              <Span variant="body1">{t('app.com.pages.contact.email')}</Span>
            </BubbleLineA>
            <BubbleLine>
              <ContactIcon>
                <SvgIcon icon={Icons.LOCATION_OUTLINE} size={2} />
              </ContactIcon>
              <Span variant="body1">{ReactHtmlParser(t('app.com.pages.contact.address'))}</Span>
            </BubbleLine>
            <BubbleLine>
              <ContactIcon>
                <BTW>{t('app.com.pages.contact.vat')}</BTW>
              </ContactIcon>
              <Span variant="body1">{t('app.com.pages.contact.btw')}</Span>
            </BubbleLine>
          </BubbleInner>
        </Bubble>
        {showSocial && <Socials />}
      </BubbleWrapperInside>
    </BubbleWrapper>
  );
};
