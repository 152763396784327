import React, { FC } from 'react';
import { FlexibleContent } from '../flexible-content/types';
import { Collapsable, Button, DosAndDonts, Wysiwyg } from '../..';
import { DoubleButton } from '../flexible-content/DoubleButton';
import { TitleWithImage } from '../flexible-content/TitleWithImage';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  DoublePhoto,
  Html,
  PhotoText,
  SinglePhoto,
  TextPhoto,
} from '../flexible-content/article-content';

export type FlexibleTypes =
  | 'button'
  | 'double_button'
  | 'wysiwyg'
  | 'collapsible'
  | 'doDont'
  | 'title_photo'
  | 'wordpress'
  | 'photo_text'
  | 'text_photo'
  | 'double_photo'
  | 'single_photo'
  | 'html';

export type FlexibleContentRendererProps = {
  item: unknown;
  type: FlexibleTypes;
};

export const FlexibleContentRenderer: FC<FlexibleContentRendererProps> = ({ item, type }) => {
  const fields: FlexibleContent = item as FlexibleContent;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <>
      {
        {
          wysiwyg: <Wysiwyg title={fields.title} content={fields.content} />,
          doDont: (
            <DosAndDonts
              titleDo={fields.titleDo}
              contentDo={fields.contentDo}
              titleDont={fields.titleDont}
              contentDont={fields.contentDont}
            />
          ),
          button: (
            <Button variant={'gradient'} href={fields.buttonLink}>
              {fields.buttonTitle}
            </Button>
          ),
          double_button: <DoubleButton fields={fields} />,
          collapsible: <Collapsable title={fields.title} text={fields.content} />,
          title_photo: <TitleWithImage title={fields.title} image={fields.image} />,
          wordpress: <Wysiwyg title={fields.title} content={fields.content} />,
          photo_text: <PhotoText fields={fields} isMobile={isMobile} isTablet={isTablet} />,
          text_photo: <TextPhoto fields={fields} isMobile={isMobile} isTablet={isTablet} />,
          double_photo: <DoublePhoto fields={fields} isMobile={isMobile} isTablet={isTablet} />,
          single_photo: <SinglePhoto fields={fields} isMobile={isMobile} isTablet={isTablet} />,
          html: <Html fields={fields} isMobile={isMobile} isTablet={isTablet} />,
        }[type]
      }
    </>
  );
};
