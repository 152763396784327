import React from 'react';
import { Caption, CoveredImage, CustomGrid, CustomGridItem } from '../Atoms';

export const SinglePhoto = ({ fields, isMobile, isTablet }) => {
  return (
    <CustomGrid mobile={isMobile} noMarginBottom={false}>
      <CustomGridItem variant="center" mobile={isMobile} tablet={isTablet}>
        <CoveredImage
          src={fields.image}
          alt={fields.alt}
          title={fields.alt}
          loading="lazy"
          maxWidth={'100%'}
          dontCover={isTablet}
        />
        <Caption variant="caption" maxWidth={'100%'}>
          {fields.caption}
        </Caption>
      </CustomGridItem>
    </CustomGrid>
  );
};
