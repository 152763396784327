import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { SvgIcon } from '../svg-icon';

export const StyledLink = styled(({ direction = 'column', center, ...restProps }) => (
  <a {...restProps} />
))`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-grow: 1;
  align-items: ${({ center }) => (center ? 'center' : 'start')};
  text-decoration: none;
  color: inherit;
`;

export const StyledReviewCard = styled(
  ({ bordered, equalHeight, mobile, selectable, ...restProps }) => <div {...restProps} />
)`
  background: #fff;
  box-shadow: ${({ theme, bordered, mobile }) =>
    bordered && !mobile ? 'none' : theme.config.defaultBoxShadow};
  border: ${({ theme, bordered, mobile }) =>
    bordered && !mobile ? `0.2rem solid ${theme.palette.grey['A200']}` : 'none'};
  position: relative;
  border-radius: 1.4rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const ReviewCardAuthor = styled.div`
  display: flex;
`;

export const ReviewCardRatingBalloon = styled(({ color, size = 'medium', ...restProps }) => (
  <div {...restProps} />
))`
  position: absolute;
  right: -1.5rem;
  top: ${({ size }) => (size === 'big' ? '-2rem' : '-2.5rem')};
  width: ${({ size }) => (size === 'big' ? '6rem' : '5rem')};
  height: ${({ size }) => (size === 'big' ? '6rem' : '5rem')};
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-bottom-left-radius: 0;

  font-size: 2.5rem;
  color: #fff;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const GoogleReviewCardRatingBalloon = styled(({ size = 'medium', ...restProps }) => (
  <div {...restProps} />
))`
  position: absolute;
  right: -1.5rem;
  top: ${({ size }) => (size === 'big' ? '-2rem' : '-2.5rem')};
  width: ${({ size }) => (size === 'big' ? '6rem' : '5rem')};
  height: ${({ size }) => (size === 'big' ? '6rem' : '5rem')};
  background: url('/google.png') no-repeat center;
  background-size: 50%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-bottom-left-radius: 0;
  box-shadow: -1px 4px 4px 1px #ccc;

  font-size: 2.5rem;
  color: #fff;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const ReadMore = styled(({ color, size = 'medium', ...restProps }) => (
  <div {...restProps} />
))`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-size: 1.1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: ${({ color }) => color};
  svg {
    margin-left: 0.2rem;
    margin-top: 0.2rem;
  }
`;

export const ReviewCardName = styled(Typography)`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  text-transform: uppercase;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 3rem;
`;
export const ReviewCardDate = styled(Typography)`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-size: 1.2rem;
`;
export const ReviewCardTitle = styled(({ teaser, ...restProps }) => <Typography {...restProps} />)`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  line-height: 2.6rem;
  ${({ teaser }) =>
    teaser &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  `}
`;
export const ReviewCardText = styled(({ teaser, lines, ...restProps }) => (
  <Typography {...restProps} />
))`
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  margin-bottom: .5rem;
  ${({ teaser, lines }) =>
    teaser &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${lines}; /* number of lines to show */
    -webkit-box-orient: vertical;
  `}
`;

export const ReviewCardHeader = styled.div`
  width: 100%;
  padding: 2rem 1.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey['A200']};
`;

export const ReviewCardSubHeader = styled.div`
  width: 100%;
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-size: 1.2rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey['A200']};
  img {
    margin-right: 1rem;
    :last-child {
      margin-left: 2rem;
    }
  }
`;

export const ReviewCardImage = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 0.4rem;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  img {
    width: 100%;
    padding: 5px;
    object-fit: cover;
  }
`;
export const ReviewCardBody = styled(({ mobile, teaser, ...restProps }) => <div {...restProps} />)`
  position: relative;
  width: 100%;
  padding: 2rem 1.5rem 1rem;
  ${({ teaser }) =>
    teaser &&
    `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
  `}
  * {
    z-index: 9;
  }
  display: flex;
  flex-direction: column;
`;

export const ReviewButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 0.1rem solid ${({ theme }) => theme.palette.grey['500']};
`;

export const ReviewButton = styled(({ active, ...restProps }) => <div {...restProps} />)`
  display: flex;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  justify-content: center;
  align-items: center;
  margin: 0 0.4rem;
  padding: 2rem;
  color: ${(props) => (props.active ? '#009369' : '#3A3A3A')};
  &:first-child {
    border-right: 0.1rem solid ${({ theme }) => theme.palette.grey['500']};
  }

  img {
    margin-right: 0.5rem;
  }
`;

export const IconButtonWrapper = styled(({ active, ...restProps }) => <div {...restProps} />)`
  margin: 0 0.4rem;
`;

export const NewLabel = styled(({ mobile, ...restProps }) => <Typography {...restProps} />)`
  position: absolute;
  left: -1rem;
  top: -1rem;
  padding: 0.3rem 0.8rem;
  background: ${({ mobile, theme }) => (mobile ? theme.config.gradients.default : '#000')};
  color: #fff;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-weight: 800;
  font-size: 1.2rem;
  border-radius: 0.4rem;
`;

export const ReviewCardSvgIcon = styled(({ mobile, ...restProps }) => <SvgIcon {...restProps} />)`
  width: ${({ mobile }) => (mobile ? '6rem' : '8rem')};
  height: ${({ mobile }) => (mobile ? '6rem' : '8rem')};
  transform: rotate(180deg);
  position: absolute;
  z-index: 0;
  right: 0;
  top: ${({ mobile }) => (mobile ? '4rem' : '5rem')};
`;

export const ProConChipWrapper = styled(
  ({ mobile, tablet, equalHeight, extraPaddingRight, size = 'medium', ...restProps }) => (
    <div {...restProps} />
  )
)`
  margin: ${({ mobile, size }) => (mobile || size === 'small' ? '0 -0.3rem' : '0 -0.5rem')};
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: ${({ extraPaddingRight }) => (extraPaddingRight ? 'calc(100% - 3rem)' : '100%')};
  height: ${({ tablet, equalHeight }) => !tablet && equalHeight && '3.4rem'};
`;

export const AutoTranslatedIcon = styled(({ position, ...restProps }) => <div {...restProps} />)`
  position: ${({ position }) => (position === 'inText' ? 'relative' : 'absolute')};
  width: 3.4rem;
  height: 3.4rem;
  background: ${({ theme }) => theme.palette.grey['A200']};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ position }) =>
    position === 'bottomRight'
      ? `
        bottom: 1rem;
        right: 1rem;
       `
      : position === 'bottomRightWithPadding'
      ? `
        bottom: calc(1rem + 33px);
        right: 1rem;
       `
      : position === 'topRight'
      ? `
        top: 3.3rem;
        right: 1rem;`
      : position === 'inText'
      ? `
        float: left;
        margin-right: 0.5rem;
        margin-top: -1rem;`
      : `
        left: 0;
        top: 0;
        transform: translate(-50%,-50%);
        `}

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
