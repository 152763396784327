import { QueryFunction, QueryFunctionContext, MutationFunction } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { getURLWithoutSubdomain } from '@homeproved/shared/util';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { decodeJwt } from './decodeJwt';
const cookies = new Cookies();

const COOKIE_NAME = 'app_jwt';

export const axiosQueryDataWrapper = <TResult>(
  queryFn: QueryFunction<AxiosResponse<TResult>>
) => async (context: QueryFunctionContext) => {
  const { data } = await queryFn(context);

  return data;
};

export const axiosMutationDataWrapper = <TResult, TVariables>(
  mutationFn: MutationFunction<AxiosResponse<TResult>, TVariables>,
  headers: Record<string, string>
) => async (variables: TVariables) => {
  const jwt = cookies.get(COOKIE_NAME);
  const decodedJwt = jwt && decodeJwt(cookies.get(COOKIE_NAME));
  if (decodedJwt != null && decodedJwt.exp - moment().unix() - 60 * 60 * 24 < 0) {
    //jwt will expire in less than 24 hours -> refresh token
    const cookieDomain =
      typeof window !== 'undefined' ? getURLWithoutSubdomain(window.location.hostname) : undefined;
    const { data } = await axios(
      (process.env.NEXT_PUBLIC_NX_API_ROUTE || '') + '/api/auth/refresh',
      {
        method: 'POST',
        headers,
      }
    );
    cookies.set(COOKIE_NAME, data.accessToken, {
      expires: moment().add(data.expiresIn, 'seconds')?.toDate(),
      path: '/',
      domain: cookieDomain,
    });
  }
  const { data } = await mutationFn(variables);
  return data;
};
