import React, { FC } from 'react';
import styled from 'styled-components';

type PaintBucketProps = {
  className?: string;
};

const Wrapper = styled.div`
  svg {
    display: block;
    width: 100%;
  }
`;

export const PaintBucket: FC<PaintBucketProps> = ({ className }) => (
  <Wrapper className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 87">
      <path
        d="M39.5 3h0C19.3 3 3 19.3 3 39.5v2.3c0 2.1 1.7 3.7 3.7 3.7 0 0 0 0 0 0h65.5c2.1 0 3.7-1.7 3.7-3.7v-2.3C76 19.4 59.7 3 39.5 3c0 0 0 0 0 0z"
        fill="none"
        stroke="#67809f"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path fill="#ef8c3e" d="M10.6 33.1h43.7v51.4H10.6z" />
      <path fill="#dd6f24" d="M54.3 33.1h14.1v51.4H54.3z" />
      <path
        d="M8.3 84.8s0 .1 0 0c0 1.2.9 2.2 2.1 2.2h58.1c1.2 0 2.1-1 2.1-2.1v-.1H8.3z"
        fill="#2c3e50"
      />
      <path d="M68.6 82.8H10.4c-1.1 0-2.1.9-2.1 2h62.4c-.1-1.1-1-2-2.1-2z" fill="#4e5a6d" />
      <path d="M8.3 33.1c0 1.2.9 2.2 2.1 2.2h58.1c1.2 0 2.1-1 2.1-2.1v-.1H8.3z" fill="#2c3e50" />
      <path d="M68.6 31H10.4c-1.1 0-2.1.9-2.1 2h62.4c-.1-1.1-1-2-2.1-2z" fill="#4e5a6d" />
      <path
        d="M64.7 32.6c-.3-.9-1.1-1.6-2.1-1.5H16.4c-1 0-1.8.6-2.1 1.5-.3 1.1.3 2.3 1.5 2.6.2.1.4.1.6.1h3.5c1.4 0 2.5 1.1 2.5 2.5v13.3c0 .8.5 1.4 1.3 1.6.8.1 1.6-.4 1.8-1.2V46.6c0-.6.5-1.1 1.1-1.1H26.9c.5.1.9.5.9 1.1v1.2c0 .7.4 1.3 1 1.5.8.3 1.7-.1 2-1 .1-.2.1-.4.1-.5v-.4c0-.6.5-1.1 1.1-1.1h.2c.6 0 1.1.5 1.1 1.1v8.7c0 .7.4 1.3 1 1.5.8.3 1.7-.1 2-1 .1-.2.1-.4.1-.5V51c0-.6.5-1.1 1.1-1.1h.2c.6 0 1.1.5 1.1 1.1v13.6c0 1.2.8 2.3 2 2.6 1.4.3 2.7-.6 2.9-1.9 0-.2.1-.3.1-.5V38.5c0-1.8 1.4-3.2 3.2-3.2h15.8c1.2 0 2.1-1 2.1-2.1-.1-.3-.1-.5-.2-.6z"
        fill="#fff"
      />
      <path
        d="M34.7 54.4c-.5 0-.9.4-.9.9v.7c0 .5.4.9.9.9s.9-.4.9-.9v-.7c0-.5-.4-.9-.9-.9zM23.8 49.2c-.5 0-.9.4-.9.9v.7c0 .5.4.9.9.9s.9-.4.9-.9v-.7c0-.5-.4-.9-.9-.9zM37.5 45.8c-.6 0-1.2.5-1.2 1.2v4.1c0-.6.5-1.2 1.2-1.2.6 0 1.2.5 1.2 1.2V47c0-.7-.5-1.2-1.2-1.2zM26.5 41.5c-.6 0-1.2.5-1.2 1.2v4.1c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2v-4.1c0-.7-.5-1.2-1.2-1.2z"
        fill="#d9e0df"
      />
      <path d="M11.2 43c0-.6-.5-1-1-1H7.9c-1.1 0-2 .9-2 2.1v1.5h5.4V43z" fill="#4e5a6d" />
      <path d="M5.9 45.5V47c0 1.1.9 2.1 2 2.1h2.3c.6 0 1-.4 1-1v-2.5H5.9z" fill="#2c3e50" />
      <path d="M67.7 43c0-.6.5-1 1-1H71c1.1 0 2 .9 2 2.1v1.5h-5.4l.1-2.6z" fill="#4e5a6d" />
      <path
        d="M73.1 45.5V47c0 1.1-.9 2.1-2 2.1h-2.3c-.6 0-1-.4-1-1v-2.5h5.3zM30.1 6.7h18.8c1.5 0 2.7-1.2 2.7-2.7H27.3c0 1.5 1.3 2.7 2.8 2.7z"
        fill="#2c3e50"
      />
      <path
        d="M48.9 1.2H30.1c-1.5 0-2.7 1.2-2.7 2.7h24.3c-.1-1.4-1.3-2.7-2.8-2.7z"
        fill="#67809f"
      />
      <path
        d="M35.9 34H24.3c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h11.6c.5 0 .9.4.9.9s-.4.9-.9.9zM41.9 34h-3.1c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h3.1c.5 0 .9.4.9.9-.1.5-.5.9-.9.9z"
        fill="#d9e0df"
      />
    </svg>
  </Wrapper>
);
