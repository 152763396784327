import React, { FC } from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type HomeShapeScoreProps = {
  score: number;
  max?: number;
  totalReviews?: string;
  className?: string;
  extendedView?: boolean;
  color?: string;
  com?: boolean;
  noScoreSmaller?: boolean;
  colorScheme?: 'light' | 'dark';
  size?: number;
  noMax?: boolean;
};

const Wrapper = styled(({ bigger, size, ...restProps }) => <div {...restProps} />)`
  position: relative;
  width: ${({ bigger, size }) => (size ? size + 1 + 'rem' : bigger ? '10.5rem' : '7.5rem')};
`;

const HomeShape = styled(SvgIcon)`
  width: 100%;
  height: auto;
`;

const Rating = styled(({ totalReviews, extendedView, colorScheme, ...restProps }) => (
  <div {...restProps} />
))`
  position: absolute;
  top: ${({ totalReviews, extendedView }) =>
    totalReviews != null && !extendedView ? '45%' : '55%'};
  left: 50%;
  display: flex;
  color: ${({ colorScheme, theme }) =>
    colorScheme === 'dark' ? theme.palette.common.black : theme.palette.common.white};
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
  font-weight: 900;
  align-items: baseline;
`;

const Score = styled(({ extendedView, size, ...restProps }) => <span {...restProps} />)`
  font-size: ${({ extendedView, size }) =>
    size ? size / 2 + 'rem' : extendedView ? '3.5rem' : '2.5rem'};
  font-family: ${({ theme }) => theme.config.fonts.CabritoExtended};
  font-weight: 700;
`;

const Max = styled(({ extendedView, ...restProps }) => <span {...restProps} />)`
  font-size: ${({ extendedView }) => (extendedView ? '2.2rem' : '1rem')};
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
  font-weight: 700;
`;

const TotalReviews = styled(({ colorScheme, ...restProps }) => <div {...restProps} />)`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-size: 1.1rem;
  text-align: center;
  color: ${({ colorScheme, theme }) =>
    colorScheme === 'dark' ? theme.palette.common.black : theme.palette.common.white};
  position: absolute;
  top: 65%;
  right: 0;
  left: 0;
`;

const CollectReviews = styled(({ smaller, ...restProps }) => <div {...restProps} />)`
  position: absolute;
  top: 60%;
  left: 50%;
  color: ${({ theme }) => theme.palette.grey['600']};
  transform: translate(-50%, -50%);
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
  font-weight: 700;
  width: 6.5rem;
  text-align: center;
  font-size: ${({ smaller }) => (smaller ? '1rem' : '1.2rem')};
`;

export const HomeShapeScore: FC<HomeShapeScoreProps> = ({
  score,
  max = 5,
  totalReviews,
  color,
  className,
  extendedView = false,
  com = false,
  noScoreSmaller = false,
  colorScheme,
  size,
  noMax,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const roundedScore = Math.round((score / 2) * 10) / 10;

  const houseColor = () => {
    if (color) {
      return color;
    }
    if (!color && colorScheme) {
      return colorScheme === 'light' ? theme.palette.grey[800] : theme.palette.common.white;
    }
    if (!color && !colorScheme) {
      return roundedScore > 0 ? theme.palette.grey[800] : theme.palette.grey[200];
    }
  };

  return (
    <Wrapper className={className} bigger={extendedView} size={size}>
      <HomeShape viewBox="0 0 84 74.9">
        <path
          fill={houseColor()}
          d="M81.9 26.2L44.7.9c-1.9-1.3-4.4-1.2-6.3 0L2 26.4c-1.2.8-2 2.2-2 3.7v44.8h84v-45c0-1.5-.8-2.9-2.1-3.7z"
        />
      </HomeShape>
      {roundedScore > 0 ? (
        <>
          <Rating totalReviews={totalReviews} colorScheme={colorScheme} extendedView={extendedView}>
            <Score size={size} extendedView={extendedView}>
              {roundedScore === 5.0 ? 5 : roundedScore}
            </Score>
            {!noMax ? <Max extendedView={extendedView}>/ {max}</Max> : null}
          </Rating>
          {!!totalReviews && !extendedView && (
            <TotalReviews colorScheme={colorScheme}>
              {`${totalReviews} ${t('app.pro.dashboard.homeprovedScore.reviews')}`}
            </TotalReviews>
          )}
        </>
      ) : (
        <CollectReviews smaller={noScoreSmaller}>
          {com
            ? t('app.com.pages.companySearch.mainSection.collectReviews')
            : t('app.pro.dashboard.homeprovedScore.collectReviews')}
        </CollectReviews>
      )}
    </Wrapper>
  );
};
