import React, { FC } from 'react';
import styled from 'styled-components';
import { Icons, SvgIcon } from '../svg-icon';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Slider from 'react-slick';
import { MediaResponse } from '@homeproved/shared/data-access';

const StyledImageCarrousel = styled(({ mobile, dotsAreShowing, card, ...restProps }) => (
  <div {...restProps} />
))`
  width: 100%;
  padding: ${({ card, mobile, dotsAreShowing }) =>
    card && mobile && dotsAreShowing ? '20px 20px 30px 20px;' : '0'};
  margin-bottom: ${({ card, dotsAreShowing }) =>
    card ? '1.5rem;' : dotsAreShowing ? '3rem' : '1rem'};
  .slick-slider {
    width: 100%;
    height: 100%;
    ${({ card }) => (card ? 'margin: 0!important;' : '')}
    .slick-track {
      display: flex !important;
      margin: 0;
      width: auto;
    }
    .slick-list {
      margin: 0 -1rem ${({ card }) => (card ? '!important;' : '')};
      ${({ card }) => (card ? 'padding: 0!important;' : '')}
    }
    .slick-slide {
      width: 100%;
      height: 100%;
      padding: 0 1rem ${({ card }) => (card ? '!important;' : '')};
    }
    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;
    }
  }
  .slick-arrow {
    &:before {
      content: none;
    }
    &.slick-disabled {
      svg,
      path {
        fill: ${({ theme }) => theme.palette.grey[500]};
      }
      cursor: default;
    }
    &.slick-prev {
      left: -2.4rem;
    }
    &.slick-next {
      right: -3.4rem;
    }
  }
  .slick-dots {
    bottom: -3rem;
    li {
      button {
        width: 1.2rem;
        height: 1.2rem;
        background-color: ${({ theme }) => theme.palette.grey[200]};
        border-radius: 50%;
        &:before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background-color: ${({ theme }) => theme.palette.primary.main};
        }
      }
    }
  }
`;

const LeftArrow = styled(({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <SvgIcon size={3.5} color="gradient" icon={Icons.ANGLE_LEFT} />
  </div>
))``;

const RightArrow = styled(({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <SvgIcon size={3.5} color="gradient" icon={Icons.ANGLE_RIGHT} />
  </div>
))``;

const Slide = styled.div`
  cursor: pointer;
  max-height: 150px;
  border-radius: 0.4rem;
  overflow: hidden;
  margin-bottom: 10px;
  max-width: 303px;
  display: flex;
  img {
    width: 100%;
    object-fit: cover;
    height: 150px;
  }
`;

export type ImageCarrouselProps = {
  card: boolean;
  isMobile: boolean;
  images: MediaResponse[];
  handleClick: (index: number, event) => void;
};

export const ImageCarrousel: FC<ImageCarrouselProps> = ({
  card,
  isMobile,
  images,
  handleClick,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const settings = {
    centerMode: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    focusOnSelect: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1039,
        settings: {
          arrows: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const isImage = (file) => {
    return file?.data.mime_type?.includes('image');
  };

  return (
    <StyledImageCarrousel
      card={card}
      mobile={isTablet || isMobile}
      dotsAreShowing={images.length > 1 && isMobile}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <Slide key={image.data.id} onClick={(event) => handleClick(index, event)}>
            {isImage(image) ? (
              <div key={index}>
                <img src={image?.data?.original} alt="" />
              </div>
            ) : (
              <div key={index}>
                <img
                  src={image.data.customProperties?.thumbnail?.data?.original}
                  alt={image.data.customProperties?.thumbnail?.data?.fileName}
                />
              </div>
            )}
          </Slide>
        ))}
      </Slider>
    </StyledImageCarrousel>
  );
};
