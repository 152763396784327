export const calculatePercentage = (value: number, total: number) =>
  total === 0 ? 0 : Math.ceil((value / total) * 100);

export const formatScore = (value: number) => {
  const score = Math.round((value / 2) * 10) / 10;
  if (value === 0) {
    return 0;
  }
  if (value === 10) {
    return 5;
  }
  if (value < 1) {
    return 1;
  }
  // remove decimal if it's zero (e.g. 4.0 -> 4)
  if (score % 1 === 0) {
    return score.toFixed(0);
  }
  return score.toFixed(1);
};
