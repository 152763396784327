import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import Rating from '@material-ui/lab/Rating';
import { getStarColor } from '../theme/theme';
import tinycolor from 'tinycolor2';
import { HomeShapeScore } from '../homeproved-score/HomeShapeScore';
import { formatScore } from '../utils/calculatePercentage';

export type StarsProps = {
  count: number;
  size?: number;
  className?: string;
  colorScheme?: string;
  showBackground?: boolean;
  showRating?: boolean;
  showHomeScore?: boolean;
  showScore?: boolean;
  google?: boolean;
  primary?: string;
  secondary?: string;
  top?: string;
};

const StyledRating = styled(
  ({
    color,
    colorScheme,
    size,
    google,
    showScore,
    showHomeScore,
    showBackground,
    background,
    ...restProps
  }) => <Rating {...restProps} />
)`
  font-size: ${({ size }) => `${size}rem`};
  background: ${({ background }) => (background ? background : 'transparent')};
  border-radius: 5px;
  padding: ${({ background }) => (background ? '5px' : '0')};
  margin-right: ${({ showScore, showHomeScore }) => (showHomeScore || showScore ? '1rem' : 0)};

  .MuiRating-decimal {
    background-color: ${({ color, showBackground }) =>
      showBackground ? color + '4D' : 'transparent'};
    margin: 1px;
    border-radius: 2px;
  }

  .MuiRating-iconFilled {
    color: ${({ color }) => color};
  }

  .MuiRating-iconEmpty {
    color: ${({ color, showBackground, theme, colorScheme }) =>
      showBackground
        ? color + '59'
        : colorScheme === 'dark'
        ? theme.palette.grey[400]
        : theme.palette.common.white};
  }
`;

const RatingCount = styled(({ background, colorScheme, showTriangle, ...restProps }) => (
  <Box {...restProps} />
))`
  background: ${({ background, theme }) => (background ? background : theme.palette.grey[800])};
  color: ${({ theme, background }) =>
    background && tinycolor(background).isLight()
      ? theme.palette.common.black
      : theme.palette.common.white};
  border-radius: 5px;
  padding: 5.5px 8px;
  margin-left: -8px;
  height: 100%;
  font-weight: 800;
  &:after {
    ${({ showTriangle }) => !showTriangle && 'display:none;'}
    content: '';
    width: 0;
    height: 0;
    top: 90%;
    right: 40%;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid
      ${({ background, theme }) => (background ? background : theme.palette.grey[800])};
  }
`;

const RatingCountScore = styled(({ size, ...restProps }) => <Box {...restProps} />)`
  color: ${({ theme }) => theme.palette.common.black};
  height: 100%;
  font-weight: 800;
  font-size: ${({ size }) => `${size + 0.5}rem`};
  margin-left: ${({ size }) => `${size / 3}rem`};
  span {
    font-size: ${({ size }) => `${size / 2}rem`};
  }
`;

export const Stars: FC<StarsProps> = ({
  colorScheme,
  showHomeScore,
  top,
  showScore,
  showRating,
  showBackground = true,
  count,
  size = 2.5,
  className,
  google,
  primary,
  secondary,
}) => {
  const color = getStarColor(count);
  const score = formatScore(count);

  return (
    <Box display="flex" alignItems="center" position="relative" top={top}>
      <StyledRating
        colorScheme={colorScheme}
        background={primary}
        showBackground={showBackground}
        showScore={showScore}
        showHomeScore={showHomeScore}
        value={score >= 1 ? score : 0}
        precision={0.5}
        readOnly={true}
        color={google || count == 0 ? '#3A3A3A' : secondary ? secondary : color}
        size={size}
      />
      {showScore && score ? (
        <RatingCount size={size} showTriangle={true} colorScheme={colorScheme} background={primary}>
          {score}
        </RatingCount>
      ) : null}
      {showRating && score ? (
        <RatingCountScore size={size}>
          {score} <span>/5</span>
        </RatingCountScore>
      ) : null}
      {showHomeScore && score ? <HomeShapeScore noMax size={3} score={count} com={true} /> : null}
    </Box>
  );
};

export default Stars;
