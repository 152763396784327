import kebabCase from 'lodash/kebabCase';
import qs from 'qs';

export const toggledArray = <K extends unknown>(array: K[], value: K) =>
  array.includes(value) ? array.filter((e) => e !== value) : [...array, value];

export const objectToQueryString = (obj) => {
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const createCitySlug = (cityName) => kebabCase(cityName.toLowerCase());
export const getInitials = (name: string) => {

  return name?.match(/(\b\S)?/g)
    .join('')
    .slice(0, 2)
    .toUpperCase();
};
export const createURL = (state) => qs.stringify(state);
export const urlToSearchState = (url) => qs.parse(url);
