import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Typography } from '@material-ui/core';

type TextRecaptchaProps = {
  center?: boolean;
  right?: boolean;
  color?: string;
};

const Text = styled(({ center, right, color, ...restProps }) => <Typography {...restProps} />)`
  font-size: 1rem;
  color: ${({ theme, color }) => (color ? color : theme.palette.grey[700])};
  a {
    color: ${({ theme, color }) => (color ? color : theme.palette.grey[700])};
  }
  text-align: ${({ center, right }) => (center ? 'center' : right ? 'right' : 'left')};
`;

export const TextRecaptcha: FC<TextRecaptchaProps> = ({ center, right, color }) => {
  const { t } = useTranslation();

  return (
    <Text variant="body1" center={center} right={right} color={color}>
      {ReactHtmlParser(t('recaptcha'))}
    </Text>
  );
};
