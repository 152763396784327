import React from 'react';
import { Caption, CustomGrid, CustomGridItem, FlexibleContentText, StyledImage } from '../Atoms';
import ReactHtmlParser from 'react-html-parser';

export const TextPhoto = ({ fields, isMobile, isTablet }) => {
  return (
    <CustomGrid mobile={isMobile} noMarginBottom={false}>
      <CustomGridItem variant="left" mobile={isMobile} tablet={isTablet} order={isTablet ? 2 : 1}>
        <FlexibleContentText tablet={isTablet} mobile={isMobile} maxWidth={'100%'}>
          {ReactHtmlParser(fields.text)}
        </FlexibleContentText>
      </CustomGridItem>
      <CustomGridItem variant="right" mobile={isMobile} tablet={isTablet} order={isTablet ? 1 : 2}>
        <StyledImage
          src={fields.image}
          alt={fields.caption}
          title={fields.caption}
          loading="lazy"
          maxWidth={'100%'}
        />
        <Caption variant="caption" maxWidth={'100%'}>
          {fields.caption}
        </Caption>
      </CustomGridItem>
    </CustomGrid>
  );
};
