import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../buttons/Button';
import { Menu, MenuItem } from '@material-ui/core';
import { ParsedUrlQuery } from 'querystring';
import { useTranslation } from 'react-i18next';

export type FilterOption = {
  label: string;
  selected: boolean;
  handle: () => void;
};

export type FilterMenuProps = {
  filterOptions: FilterOption[];
  setSelected: (label: string) => void;
  query: ParsedUrlQuery;
};

const StyledFilterMenu = styled.div``;
const StyledButton = styled(Button)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey['A400']};
`;

export const FilterMenu: FC<FilterMenuProps> = ({ filterOptions, setSelected, query }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilterMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation();
  const closeFilterMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (query.rating === '' && query.date === 'desc') {
      setSelected(t('app.pro.pages.reviews.filter.newOld'));
    } else if (query.rating === '' && query.date === 'asc') {
      setSelected(t('app.pro.pages.reviews.filter.oldNew'));
    } else if (query.rating === 'desc' && query.date === '') {
      setSelected(t('app.pro.pages.reviews.filter.positive'));
    } else {
      setSelected(t('app.pro.pages.reviews.filter.negative'));
    }
  }, []);

  return (
    <StyledFilterMenu>
      <StyledButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="filter"
        onClick={openFilterMenu}
      >
        {filterOptions.find(({ label, selected }) => selected).label}
      </StyledButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeFilterMenu}
      >
        {filterOptions.map(
          ({ label, selected, handle }) =>
            !selected && (
              <MenuItem
                key={label}
                onClick={() => {
                  closeFilterMenu();
                  handle();
                  setSelected(label);
                }}
              >
                {label}
              </MenuItem>
            )
        )}
      </Menu>
    </StyledFilterMenu>
  );
};
