import React, { FC } from 'react';
import styled from 'styled-components';
import { Icons, SvgIcon } from '../..';

export interface LockedProps {
  locked?: boolean;
  iconPosition?:
    | 'top-left'
    | 'top-left-negative'
    | 'top-right'
    | 'bottom-right'
    | 'bottom-right-negative'
    | 'center';
  iconSize?: number;
  iconColor?: string;
  overlayColor?: 'light' | 'dark';
  offset?: number;
  fullHeight?: boolean;
  isMobile?: boolean;
  allowPointerEvents?: boolean;
  margin?: string;
  iconMargin?: string;
  overlayBorderRadius?: string;
}

const Wrapper = styled(
  ({
    locked,
    overlayColor,
    offset,
    fullHeight,
    isMobile,
    allowPointerEvents,
    margin,
    overlayBorderRadius,
    ...restProps
  }) => <div {...restProps} />
)`
  position: relative;

  ${({ locked, overlayColor, offset, allowPointerEvents, theme, margin, overlayBorderRadius }) =>
    locked &&
    `
    pointer-events: ${!allowPointerEvents && 'none'};
    margin: ${margin && margin};

    &:after {
      content: '';
      display: block;
      background: ${overlayColor === 'dark' ? theme.palette.grey['600'] : '#fff'};
      opacity: 0.5;
      position: absolute;
      top: ${offset}rem;
      right: ${offset}rem;
      bottom: ${offset}rem;
      left: ${offset}rem;
      border-radius: ${
        overlayBorderRadius ? overlayBorderRadius : theme.config.defaultBorderRadius
      };
    }
  `}
  ${({ fullHeight }) =>
    fullHeight &&
    `
    height: 100%;
    display: flex;
  `}
  ${({ fullHeight, isMobile }) =>
    fullHeight &&
    !isMobile &&
    `
    flex-direction: column;
  `}
`;

const LockIcon = styled(({ iconPosition, margin, ...restProps }) => <SvgIcon {...restProps} />)`
  position: absolute;
  z-index: 10;
  margin: ${({ margin }) => margin};
  ${({ iconPosition }) =>
    iconPosition === 'top-left' &&
    `
    top: 1rem;
    left: 1rem;
  `}
  ${({ iconPosition }) =>
    iconPosition === 'top-left-negative' &&
    `
    top: -1rem;
    left: -1rem;
  `}
  ${({ iconPosition }) =>
    iconPosition === 'top-right' &&
    `
    top: 1rem;
    right: 1rem;
  `}
  ${({ iconPosition }) =>
    iconPosition === 'bottom-right' &&
    `
    right: 1rem;
    bottom: 1rem;
  `}
  ${({ iconPosition }) =>
    iconPosition === 'bottom-right-negative' &&
    `
    right: -1rem;
    bottom: -1rem;
  `}
  ${({ iconPosition }) =>
    iconPosition === 'center' &&
    `
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  `}
`;

export const Locked: FC<LockedProps> = ({
  children,
  locked,
  iconPosition = 'bottom-right',
  iconSize = 6,
  iconColor = '#fff',
  overlayColor = 'dark',
  offset = -0.5,
  fullHeight,
  isMobile,
  allowPointerEvents = false,
  margin = '0',
  iconMargin = '0',
  overlayBorderRadius,
}) => {
  return (
    <Wrapper
      locked={locked}
      overlayColor={overlayColor}
      offset={offset}
      fullHeight={fullHeight}
      isMobile={isMobile}
      allowPointerEvents={allowPointerEvents}
      margin={margin}
      overlayBorderRadius={overlayBorderRadius}
    >
      {children}
      {locked && (
        <LockIcon
          icon={Icons.LOCK}
          size={iconSize}
          color={iconColor}
          iconPosition={iconPosition}
          margin={iconMargin}
        />
      )}
    </Wrapper>
  );
};
