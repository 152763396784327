import React, { FC, useRef, useState, useEffect } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Icons, SvgIcon } from '@homeproved/shared/ui';
import { MediaResponse } from '@homeproved/shared/data-access';
import { useTheme } from '@material-ui/core';

import styles from './VideoPlayer.module.scss';

type VideoPlayerProps = {
  stopPropagation: (e: React.MouseEvent<HTMLDivElement>) => void;
  card: boolean;
  video: MediaResponse;
  thumbnail: MediaResponse;
};

export const VideoPlayer: FC<VideoPlayerProps> = ({ stopPropagation, card, video, thumbnail }) => {
  const videoContainer: React.RefObject<HTMLDivElement> = useRef();
  const elemVideo: React.RefObject<HTMLVideoElement> = useRef();

  const theme = useTheme();

  const [videoHasStarted, setVideoHasStarted] = useState(false);
  const [hasVideoEnded, setHasVideoEnded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(100);
  const [time, setTime] = useState(0);
  const [videoLength, setVideoLength] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const [formatedCurrent, setFormatedCurrent] = useState('00:00');
  const [formatedDuration, setFormatedDuration] = useState('00:00');

  useEffect(() => {
    mounted();
    return () => unmount();
  }, []);

  const mounted = () => {
    handleTotalTime();

    const prefixes = ['', 'moz', 'webkit', 'ms'];
    prefixes.map((prefix) => {
      document.addEventListener(prefix + 'fullscreenchange', () => {
        if (
          document.fullscreenElement === null ||
          document['webkitFullscreenElement'] === null ||
          document['mozFullScreenElement'] === null ||
          document['msFullscreenElement'] === null
        ) {
          setIsFullScreen(false);
        }
      });
    });
  };

  const unmount = () => {
    document.removeEventListener('keydown', () => ({}));
    const prefixes = ['', 'moz', 'webkit', 'ms'];
    prefixes.forEach(function (prefix) {
      document.removeEventListener(prefix + 'fullscreenchange', () => ({}));
    });
    document.removeEventListener('keydown', () => ({}));
  };

  const initialStartVideo = () => {
    elemVideo.current.play();
    setIsPlaying(true);
    setVideoHasStarted(true);
  };

  const replayVideo = () => {
    setIsPlaying(true);
    setHasVideoEnded(false);
    setTime(0);
    elemVideo.current.play();
  };

  const goFullScreen = () => {
    if (isFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document['mozCancelFullScreen']) {
        /* Firefox */
        document['mozCancelFullScreen']();
      } else if (document['webkitExitFullscreen']) {
        /* Chrome, Safari and Opera */
        document['webkitExitFullscreen']();
      } else if (document['msExitFullscreen']) {
        /* IE/Edge */
        document['msExitFullscreen']();
      }

      setIsFullScreen(false);
    } else {
      const elem = videoContainer.current;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem['mozRequestFullScreen']) {
        /* Firefox */
        elem['mozRequestFullScreen']();
      } else if (elem['webkitRequestFullscreen']) {
        /* Chrome, Safari and Opera */
        elem['webkitRequestFullscreen']();
      } else if (elem['msRequestFullscreen']) {
        /* IE/Edge */
        elem['msRequestFullscreen']();
      }

      setIsFullScreen(true);
    }
  };

  const handleTotalTime = () => {
    const sec = elemVideo.current.duration;
    const steps = Math.round(elemVideo.current.duration);

    const minutes = Math.floor(steps / 60);
    const seconds = steps - minutes * 60;

    const timeString = `${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`;

    setVideoLength(sec);
    setMaxSteps(steps);
    setFormatedDuration(timeString);
  };

  const updateVideoBar = () => {
    const sec = Math.round(elemVideo.current.currentTime);

    const minutes = Math.floor(sec / 60);
    const seconds = sec - minutes * 60;

    const timeString = `${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`;

    setTime(sec);
    setFormatedCurrent(timeString);

    if (videoLength <= elemVideo.current.currentTime) {
      setHasVideoEnded(true);
    }
  };

  const changeScene = (value) => {
    elemVideo.current.currentTime = value;
    setTime(value);
  };

  const playPauseVideo = () => {
    if (isPlaying) {
      elemVideo != undefined && elemVideo.current.pause();
      setIsPlaying(false);
    } else {
      elemVideo != undefined && elemVideo.current.play();
      setIsPlaying(true);
    }
  };

  const changeVolume = (value) => {
    elemVideo.current.volume = value / 100;
    setVolume(value);

    if (value == 0) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
    }
  };

  const muteVolume = () => {
    if (isMuted) {
      elemVideo.current.muted = false;
      setIsMuted(false);
    } else {
      elemVideo.current.muted = true;
      setIsMuted(true);
    }
  };

  return (
    <div
      onClick={stopPropagation}
      ref={videoContainer}
      className={[
        styles.videoplayer_container,
        styles.fullscreen,
        `${videoHasStarted ? styles.enabled_controls + ' ' : ''}
        ${isPlaying ? '' : styles.not_playing}
        ${card ? styles.card : ''}`,
      ].join(' ')}
    >
      {(!videoHasStarted || card) && !isPlaying && (
        <button
          className={styles.videoplayer_hoverbutton}
          style={{ background: `${theme.config.gradients.default}` }}
          onClick={initialStartVideo}
        >
          <SvgIcon
            viewBox="0 0 24 24"
            icon={Icons.PLAY_CIRCLE}
            color="#fff"
            size={7}
            className={`${card ? '' : styles.bounce}`}
          />
        </button>
      )}
      {hasVideoEnded && (
        <button
          className={styles.videoplayer_hoverbutton}
          style={{ background: `${theme.config.gradients.default}` }}
          onClick={replayVideo}
        >
          <SvgIcon viewBox="0 0 24 24" icon={Icons.REPLAY} color="#fff" size={7} />
        </button>
      )}
      <video
        poster={thumbnail?.data?.original}
        preload="none"
        ref={elemVideo}
        className={[styles.videoplayer_video, `${isFullScreen ? styles.fullscreen : ''}`].join(' ')}
        src={video?.data?.original}
        onTimeUpdate={updateVideoBar}
        onCanPlay={handleTotalTime}
        onClick={videoHasStarted ? playPauseVideo : initialStartVideo}
      />
      {!card && (
        <section className={styles.videoplayer_controls}>
          <input
            type="range"
            min="0"
            max={maxSteps}
            value={time}
            step="1"
            className={styles.videoplayer_timebar}
            style={{
              background:
                'linear-gradient(to right, rgb(217, 2, 58) 0%, rgb(239, 140, 63) ' +
                (time * 100) / maxSteps +
                '%, #BBC0C0 ' +
                (time * 100) / maxSteps +
                '%, #BBC0C0 100%)',
            }}
            onChange={(event) => changeScene(event.target.value)}
          />
          <div className={styles.videoplayer_timing}>
            <span className={styles.text_left}>{formatedCurrent}</span>
            <span className={styles.text_right}>{formatedDuration}</span>
          </div>
          <div className={styles.videoplayer_actions}>
            <div className={styles.videoplayer_left}>
              <button className={styles.videoplayer_button} onClick={playPauseVideo}>
                <SvgIcon
                  viewBox="0 0 24 24"
                  icon={isPlaying ? Icons.PAUSE : Icons.PLAY}
                  color="#fff"
                  size={2}
                />
              </button>
              <div className={styles.videoplayer_volume}>
                <button className={styles.videoplayer_button} onClick={muteVolume}>
                  {isMuted ? (
                    <SvgIcon viewBox="0 0 24 24" icon={Icons.MUTE} color="#fff" size={2} />
                  ) : (
                    <SvgIcon
                      viewBox="0 0 24 24"
                      icon={volume < 50 ? Icons.VOLUME_DOWN : Icons.VOLUME_UP}
                      color="#fff"
                      size={2}
                    />
                  )}
                </button>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={isMuted ? 0 : volume}
                  className={styles.videoplayer_volumebar}
                  style={{
                    background:
                      'linear-gradient(to right, #FFFFFF 0%, #FFFFFF ' +
                      (isMuted ? 0 : volume) +
                      '%, #BBC0C0 ' +
                      (isMuted ? 0 : volume) +
                      '%, #BBC0C0 100%)',
                  }}
                  onChange={(event) => changeVolume(event.target.value)}
                />
              </div>
            </div>
            <div className={styles.videoplayer_right}>
              <button className={styles.videoplayer_button} onClick={goFullScreen}>
                <SvgIcon
                  viewBox="0 0 24 24"
                  icon={isFullScreen ? Icons.FULLSCREEN : Icons.FULLSCREEN_EXIT}
                  color="#fff"
                  size={2}
                />
              </button>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
