import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';

export const GhostVariantButton = styled(
  ({ withIcon, arrow, withBorder, size = 'medium', ...restProps }) => (
    <MuiButton size={size} {...restProps} />
  )
)`
  position: relative;
  background: transparent;
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme, size }) =>
    size === 'large' ? '2.4rem' : size === 'small' ? '1rem' : '2rem'};
  border: ${({ withBorder, theme }) =>
    withBorder ? `.2rem solid ${theme.palette.grey['A200']}` : 'none'};
  padding: 1.22rem;
  margin: 5px;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1rem;
  white-space: nowrap;
  text-transform: none;
  &:hover {
    transform: scale(1.05);
    background: ${({theme}) => theme.palette.primary.main};
  }
  &:disabled {
    background: ${({ theme }) => theme.palette.grey['200']};
    color: ${({ theme }) => theme.palette.grey['500']};
  }
  @media print {
    display: none;
  }

  .MuiButton-label svg {
    position: relative;
    margin-right: .5rem;
    top: unset;
    left: unset;
    transform: none;
  };
`;
