import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { IconButton } from '../buttons';
import { Icons, SvgIcon } from '../svg-icon';
import { ProfilePicture } from '../profile-picture/ProfilePicture';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import {
  ReviewCardAuthor,
  ReviewCardBody,
  ReviewCardDate,
  ReviewCardHeader,
  IconButtonWrapper,
  ReviewCardName,
  NewLabel,
  ReadMore,
  ReviewButtonContainer,
  StyledReviewCard,
  ReviewCardSvgIcon,
  ReviewCardText,
  ReviewCardTitle,
  GoogleReviewCardRatingBalloon,
} from './Atoms';
import moment from 'moment';
import { useWindowSize } from './hooks/useWindowSize';
import { decode } from 'html-entities';

export type GoogleReview = {
  id: number;
  picture?: string;
  isTranslation: boolean;
  initials: string;
  rating: number;
  date: string;
  title: string;
  text?: string;
  companyId: number;
  companySlug: string;
  screenName: string;
};

export type GoogleReviewCardProps = {
  review: GoogleReview;
  isMobile: boolean;
  isTablet?: boolean;
  readmoreButton?: boolean;
  equalHeight?: boolean;
  bordered?: boolean;
  showButtons?: boolean;
  checked?: boolean;
  shared?: boolean;
  favorite?: boolean;
  answered?: boolean;
  isNew?: boolean;
  teaser?: boolean;
  showQuoteSign?: boolean;
  dragging?: boolean;
  bigBalloon?: boolean;
  selectable?: boolean;
  onClick?: () => void;
};

export const GoogleReviewCard: FC<GoogleReviewCardProps> = ({
  review,
  isMobile,
  isTablet,
  bordered,
  showButtons,
  checked,
  shared,
  favorite,
  answered,
  readmoreButton,
  isNew,
  teaser,
  showQuoteSign,
  bigBalloon,
  selectable,
  equalHeight,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const tooltips = {
    checked: checked
      ? ReactHtmlParser(t('app.pro.pages.reviews.hover.checkOn'))
      : ReactHtmlParser(t('app.pro.pages.reviews.hover.checkOff')),
    shared: shared
      ? ReactHtmlParser(t('app.pro.pages.reviews.hover.shareOn'))
      : ReactHtmlParser(t('app.pro.pages.reviews.hover.shareOff')),
    favorite: favorite
      ? ReactHtmlParser(t('app.pro.pages.reviews.hover.favoriteOn'))
      : ReactHtmlParser(t('app.pro.pages.reviews.hover.favoriteOff')),
    answered: answered
      ? ReactHtmlParser(t('app.pro.pages.reviews.hover.answeredOn'))
      : ReactHtmlParser(t('app.pro.pages.reviews.hover.answeredOff')),
  };

  const [titleHeight, setTitleHeight] = useState(26);

  const ref = useRef(null);
  const windowSize = useWindowSize(equalHeight);

  useEffect(() => {
    setTitleHeight(ref.current.clientHeight);
  }, [ref, windowSize, equalHeight, review.title, review.text]);

  return (
    <StyledReviewCard
      bordered={bordered}
      mobile={isMobile}
      onClick={onClick}
      selectable={selectable}
      equalHeight={!isTablet && equalHeight}
    >
      {isNew && (
        <NewLabel mobile={isMobile} variant="body1">
          {t('app.pro.pages.reviews.new')}
        </NewLabel>
      )}
      <ReviewCardHeader>
        <ReviewCardAuthor>
          <ProfilePicture initials={review.initials} pictureUrl={review.picture} />
          <Box display="flex" flexDirection="column" ml={1}>
            <ReviewCardName variant="body1">{review.screenName}</ReviewCardName>
            <ReviewCardDate variant="body1">
              {moment(review.date).format('DD/MM/YY')}
            </ReviewCardDate>
          </Box>
        </ReviewCardAuthor>
      </ReviewCardHeader>
      <ReviewCardBody mobile={isMobile} teaser={teaser}>
        {showQuoteSign && (
          <ReviewCardSvgIcon
            icon={Icons.QUOTE}
            color={theme.palette.grey['A100']}
            mobile={isMobile}
          />
        )}
        <div ref={ref} style={{ marginBottom: '0.5rem' }}>
          <ReviewCardTitle variant="body1" teaser={teaser}>
            <SvgIcon
              icon={review.rating > 0 ? Icons.STAR_SOLID : Icons.STAR_OUTLINE}
              color={review.rating > 0 ? '#ffd700' : '#eee'}
              size={1.8}
            />
            <SvgIcon
              icon={review.rating > 1 ? Icons.STAR_SOLID : Icons.STAR_OUTLINE}
              color={review.rating > 1 ? '#ffd700' : '#eee'}
              size={1.8}
            />
            <SvgIcon
              icon={review.rating > 2 ? Icons.STAR_SOLID : Icons.STAR_OUTLINE}
              color={review.rating > 2 ? '#ffd700' : '#eee'}
              size={1.8}
            />
            <SvgIcon
              icon={review.rating > 3 ? Icons.STAR_SOLID : Icons.STAR_OUTLINE}
              color={review.rating > 3 ? '#ffd700' : '#eee'}
              size={1.8}
            />
            <SvgIcon
              icon={review.rating > 4 ? Icons.STAR_SOLID : Icons.STAR_OUTLINE}
              color={review.rating > 4 ? '#ffd700' : '#eee'}
              size={1.8}
            />
          </ReviewCardTitle>
        </div>
        {review.text !== '' ? (
          <Box flexGrow={teaser ? 1 : 0}>
            <ReviewCardText
              variant="body1"
              teaser={teaser}
              lines={!isMobile && titleHeight <= 26 ? 6 : 5}
            >
              {decode(review.text)}
            </ReviewCardText>
          </Box>
        ) : (
          <Box flexGrow={teaser ? 1 : 0} />
        )}
        {showButtons && (
          <ReviewButtonContainer>
            <IconButtonWrapper>
              <IconButton
                icon={Icons.SHARE}
                active={shared}
                variant="light"
                tooltip={tooltips.shared}
              />
            </IconButtonWrapper>
            <IconButtonWrapper>
              <IconButton
                icon={Icons.FEEDBACK}
                active={answered}
                variant="light"
                tooltip={tooltips.answered}
              />
            </IconButtonWrapper>
            <IconButtonWrapper>
              <IconButton
                icon={Icons.HEART}
                active={favorite}
                variant="light"
                tooltip={tooltips.favorite}
              />
            </IconButtonWrapper>
            <IconButtonWrapper>
              <IconButton
                icon={Icons.TINY_CHECKMARK}
                active={checked}
                variant="light"
                tooltip={tooltips.checked}
              />
            </IconButtonWrapper>
          </ReviewButtonContainer>
        )}
      </ReviewCardBody>
      <GoogleReviewCardRatingBalloon size={bigBalloon ? 'big' : 'medium'} />
      {readmoreButton && (
        <ReadMore color="#ccc">
          {t('app.com.googlereview')}
        </ReadMore>
      )}
    </StyledReviewCard>
  );
};
